const areaList = {
    province_list: {
        460000: '海南省',
    },
    city_list: {
        460400: '儋州市',
    },
    county_list: {
        460401: '那大镇',
        460402: '和庆镇',
        460403: '南丰镇',
        460404: '大成镇',
        460405: '雅星镇',
        460406: '兰洋镇',
        460407: '光村镇',
        460408: '木棠镇',
        460409: '海头镇',
        460410: '峨蔓镇',
        460411: '王五镇',
        460412: '白马井镇',
        460413: '中和镇',
        460414: '排浦镇',
        460415: '东成镇',
        460416: '新州镇',
        460417: '洋浦经济开发区',
        460418: '华南热作学院'
    },
}

export default areaList