import ajax from "./ajax"

const http = {
    index: (formdata) => {
        return ajax.post('/', formdata)
    },
    upload: (formdata) => {
        return ajax.post('/common/upload', formdata);
    },
    getAliSts: (formdata) => {
        return ajax.post('/common/getalists', formdata);
    },
    sms: {
        send: (formdata) => {
            return ajax.post('/sms/send', formdata)
        },
    },
    getCaptcha: function (formdata) {
        return ajax.post('/common/getcaptcha', formdata)
    },
    login: {
        mobileLogin: (formdata) => {
            return ajax.post('/user/mobilelogin', formdata)
        },
        logout: (formdata) => {
            return ajax.post('/user/logout', formdata)
        },
    },
    user: {
        index: (formdata) => {
            return ajax.post('/user/index', formdata)
        }
    },
    apply: {
        index: (formdata) => {
            return ajax.post('/apply/index', formdata)
        },
        add: (formdata) => {
            return ajax.post('/apply/add', formdata)
        },
        detail:(formdata)=>{
            return ajax.post('/apply/detail', formdata)
        },
        tongxingzheng:(formdata)=>{
            return ajax.post('/apply/tongxingzheng', formdata)
        },
        getActivityRiqiList:(formdata)=>{
            return ajax.post('/apply/getactivityriqilist', formdata)
        },
        getActivityNameList:(formdata)=>{
            return ajax.post('/apply/getactivitynamelist', formdata)
        },
    }
}

export default http