const areaList = {
    province_list: {
        460000: '海南省',
    },
    city_list: {
        460100: '海口市',
        460200: '三亚市',
        460300: '三沙市',
        460400: '儋州市',
        469000: '省直辖县',
    },
    county_list: {
        460105: '秀英区',
        460106: '龙华区',
        460107: '琼山区',
        460108: '美兰区',
        460202: '海棠区',
        460203: '吉阳区',
        460204: '天涯区',
        460205: '崖州区',
        460321: '西沙区',
        460322: '南沙区',
        460401: '那大镇',
        460402: '和庆镇',
        460403: '南丰镇',
        460404: '大成镇',
        460405: '雅星镇',
        460406: '兰洋镇',
        460407: '光村镇',
        460408: '木棠镇',
        460409: '海头镇',
        460410: '峨蔓镇',
        460411: '王五镇',
        460412: '白马井镇',
        460413: '中和镇',
        460414: '排浦镇',
        460415: '东成镇',
        460416: '新州镇',
        460417: '洋浦经济开发区',
        460418: '华南热作学院',
        469001: '五指山市',
        469002: '琼海市',
        469005: '文昌市',
        469006: '万宁市',
        469007: '东方市',
        469021: '定安县',
        469022: '屯昌县',
        469023: '澄迈县',
        469024: '临高县',
        469025: '白沙黎族自治县',
        469026: '昌江黎族自治县',
        469027: '乐东黎族自治县',
        469028: '陵水黎族自治县',
        469029: '保亭黎族苗族自治县',
        469030: '琼中黎族苗族自治县',
    },
}

export default areaList