import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index.index',
    component: () => import(/* webpackChunkName: "index.index" */ '../views/index/Index.vue')
  },
  {
    path: '/login/login',
    name: 'login.login',
    props: true,
    component: () => import(/* webpackChunkName: "login.login" */ '../views/login/Login.vue')
  },
  {
    path: '/apply/add',
    name: 'apply.add',
    props: true,
    component: () => import(/* webpackChunkName: "apply.add" */ '../views/apply/Add.vue')
  },
  {
    path: '/apply/edit/:id',
    name: 'apply.edit',
    props: true,
    component: () => import(/* webpackChunkName: "apply.edit" */ '../views/apply/Edit.vue')
  },
  {
    path: '/apply/tongxingzheng/:id',
    name: 'apply.tongxingzheng',
    props: true,
    component: () => import(/* webpackChunkName: "apply.tongxingzheng" */ '../views/apply/Tongxingzheng.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
