import uploadconfig from '@/config/upload';

export function oss(path, query) {
	if (!path) {
		return ''
	}
	if (path.indexOf('data:image') === 0) {
		return path;
	}
	if (query) {
		path = path + query;
	}
	if (path.indexOf('http://') >= 0 || path.indexOf('https://') >= 0) {
		return path;
	}
	return uploadconfig.url + path;
}


export default {
    oss
}