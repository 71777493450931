var store = {
    debug: false,
    state: {
        userinfo: ''
    },
    setUserinfoAction(newValue) {
        if (this.debug) console.log('setUserinfoAction triggered with', newValue)
        this.state.userinfo = newValue
    },
    clearUserinfoAction() {
        if (this.debug) console.log('clearUserinfoAction triggered')
        this.state.userinfo = ''
    }
}

export default store